import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
    FtpCreateApi,
    FtpDeleteApi,
    FtpFeedApi,
    FtpGetAllApi,
    FtpListApi,
    FtpUpdateApi,
    FtpViewApi,
    lithosFtpCreateApi,
    lithosFtpDeleteApi,
    lithosFtpFeedApi,
    lithosFtpGetAllApi,
    lithosFtpListApi,
    lithosFtpUpdateApi,
    lithosFtpViewApi,
} from '../services/api';

export const lithosFtpsSlice = createSlice({
    name: 'lithosFtps',
    initialState: {
        isLoading: false,
        ftpData: [],
        ftpFeedData: [],
        ftpfeedTotalCountData: [],
        ftpViewData: [],
        ftpCreateData: [],
        ftpDeleteData: [],
        ftpUpdateData: [],
        ftpGetAllData: [],
        FtpCreateError: [],
        error: null,
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },

        ftpGetData: (state, action) => {
            state.isLoading = false;
            state.ftpData = action.payload;
        },

        ftpFeedDataList: (state, action) => {
            state.isLoading = false;
            state.ftpFeedData = action.payload;
        },

        ftpfeedTotalCountDataList: (state, action) => {
            state.isLoading = false;
            state.ftpfeedTotalCountData = action.payload;
        },

        ftpgetAllDatalist: (state, action) => {
            state.isLoading = false;
            state.ftpGetAllData = action.payload?.data;
        },

        ftpViewData: (state, action) => {
            state.isLoading = false;
            state.ftpViewData = action.payload?.data;
        },

        ftpCreateData: (state, action) => {
            state.isLoading = false;
            state.ftpCreateData = action.payload?.data;
        },

        ftpUpdateData: (state, action) => {
            state.isLoading = false;
            state.ftpUpdateData = action.payload?.data;
        },

        ftpErrorList: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },

        ftpDeleteData: (state, action) => {
            state.isLoading = false;
            state.ftpDeleteData = action.payload;
        },

        ftpResetAuth: (state) => {
            state.isLoading = false;
            state.error = null;
            state.ftpCreateData = null;
            state.ftpUpdateData = [];
            state.FtpCreateError = [];
        },
        ftpFeedResestData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.ftpfeedTotalCountData = [];
        },
    },
});

export const {
    ftpGetData,
    ftpFeedDataList,
    ftpfeedTotalCountDataList,
    ftpViewData,
    ftpCreateData,
    ftpUpdateData,
    setLoading,
    ftpgetAllDatalist,
    ftpErrorList,
    ftpDeleteData,
    createSuccess,
    ftpResetAuth,
    FtpCreateError,
    ftpFeedResestData
} = lithosFtpsSlice.actions;

export default lithosFtpsSlice.reducer;

export const lithosFtpClientList = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpListApi(queryString, config);

        dispatch(ftpGetData(data));
    } catch (err) {
        dispatch(ftpErrorList(err));
    }
};

export const lithosFtpFeedRecordList = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpFeedApi(queryString, config);
        dispatch(ftpFeedDataList(data));
    } catch (err) {
        dispatch(ftpErrorList(err));
    }
};

export const lithosFtpfeedTotalCountRecordList = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpFeedApi(queryString, config);
        dispatch(ftpfeedTotalCountDataList(data));
    } catch (err) {
        dispatch(ftpErrorList(err));
    }
};

export const lithosFtpGetDataDrowpDown = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpGetAllApi(config);
        dispatch(ftpgetAllDatalist(data));
    } catch (err) {
        dispatch(ftpErrorList(err));
    }
};

export const lithosFtpViewList = (id) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpViewApi(id, config);

        dispatch(ftpViewData(data));
    } catch (err) {
        dispatch(ftpErrorList(err));
    }
};

export const lithosFtpUpdateList = (id, updatedata) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpUpdateApi(id, updatedata, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message);
            dispatch(ftpUpdateData(data));
        }
    } catch (error) {
        if (error.response && error.response.data.errors) {
            return dispatch(ftpErrorList(error.response.data.errors));
        } else {
            return dispatch(ftpErrorList(error.message));
        }
    }
};

export const lithosFtpCreateRequest = (ftpData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };

        const { data } = await lithosFtpCreateApi(ftpData, config);

        const { statusCode, message } = data;

        if (statusCode === 201) {
            toast.success(message);
            dispatch(ftpCreateData(data));
        }
    } catch (error) {
        if (error.response && error.response.data.errors) {
            return dispatch(ftpErrorList(error.response.data.errors));
        } else {
            return dispatch(ftpErrorList(error.message));
        }
    }
};

export const lithosFtpDeleteRequest = (delete_id) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your data is deleteing...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosFtpDeleteApi(delete_id, config);

        const { statusCode, error, errors, message } = data;
        if (error) {
            dispatch(ftpErrorList(errors));
        }
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(ftpDeleteData(data));
            dispatch(ftpGetData(data));
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId,
            });
        }
    }
};
