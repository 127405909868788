import { createSlice } from '@reduxjs/toolkit';
import { FtpLogListApi, stoneSearchFtpLogListApi, stoneSearchShopifySyncApi } from '../services/api';
import toast from 'react-hot-toast';

export const StoneSearchFtpLog = createSlice({
	name: 'stoneSearchFtpLog',
	initialState: {
		isLoading: false,
		stoneSearchFtpLogList: [],
		ShopifySyncList: [],
		error: null,
	},
	reducers: {
		setLoading: (state) => {
			state.isLoading = true;
		},

		stoneSearchFtpLogData: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpLogList = action.payload;
		},

		ftpLogErrorList: (state, action) => {
			state.error = action.payload;
			state.isLoading = false;
		},
		shopifySyncData: (state, action) => {
			state.isLoading = false;
			state.ShopifySyncList = action.payload;
		},
	},
});

export const { stoneSearchFtpLogData, setLoading, shopifySyncData, ftpLogErrorList } = StoneSearchFtpLog.actions;

export default StoneSearchFtpLog.reducer;

export const stoneSearchFtpLogListRequest = (queryString) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpLogListApi(queryString, config);

		dispatch(stoneSearchFtpLogData(data));
	} catch (error) {
		dispatch(ftpLogErrorList(error));
	}
};
export const ShopifySyncProductRequest = () => async (dispatch, getState) => {
	const toastId = toast.loading('Please wait your Product is Syncing...');
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};

		const { data } = await stoneSearchShopifySyncApi(config);
		const { statusCode, message } = data;

		if (statusCode === 200) {
			toast.success(message, {
				id: toastId,
			});

			dispatch(shopifySyncData(data));
		}
	} catch (error) {
		const { statusCode, errors } = error.response.data;
		if (statusCode === 422) {
			dispatch(ftpLogErrorList(errors));
			toast.error(errors.message);
		}
	}
};
