import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
	FtpCreateApi,
	FtpDeleteApi,
	FtpFeedApi,
	FtpGetAllApi,
	stoneSearchFtpListApi,
	FtpUpdateApi,
	FtpViewApi,
	stoneSearchFtpCreateApi,
	stoneSearchFtpViewApi,
	stoneSearchFtpUpdateApi,
	stoneSearchFtpDeleteApi,
	stoneSearchFtpFeedApi,
	stoneSearchFtpGetAllApi,
} from '../services/api';

export const stoneSearchFtpsSlice = createSlice({
	name: 'stoneSearchFtps',
	initialState: {
		isLoading: false,
		stoneSearchftpData: [],
		stoneSearchFtpFeedData: [],
		stoneSearchFtpfeedTotalCountData: [],
		stoneSearchFtpViewData: [],
		stoneSearchFtpCreateDataSuccess: [],
		stoneSearchstoneSearchFtpDeleteData: [],
		stoneSearchFtpUpdateData: [],
		stoneSearchFtpGetAllData: [],
		FtpCreateError: [],
		error: null,
	},
	reducers: {
		setLoading: (state) => {
			state.isLoading = true;
		},

		stoneSearchFtpGetData: (state, action) => {
			state.isLoading = false;
			state.stoneSearchftpData = action.payload;
		},

		stoneSearchFtpFeedDataList: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpFeedData = action.payload;
		},

		stoneSearchFtpfeedTotalCountDataList: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpfeedTotalCountData = action.payload;
		},

		stoneSearchFtpgetAllDatalist: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpGetAllData = action.payload?.data;
		},

		stoneSearchFtpViewData: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpViewData = action.payload?.data;
		},

		stoneSearchFtpCreateData: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpCreateDataSuccess = action.payload?.data;
		},

		stoneSearchFtpUpdateData: (state, action) => {
			state.isLoading = false;
			state.stoneSearchFtpUpdateData = action.payload?.data;
		},

		stoneSearchFtpErrorList: (state, action) => {
			state.error = action.payload;
			state.isLoading = false;
		},

		stoneSearchFtpDeleteData: (state, action) => {
			state.isLoading = false;
			state.stoneSearchstoneSearchFtpDeleteData = action.payload;
		},

		stoneSearchFtpResetAuth: (state) => {
			state.isLoading = false;
			state.error = null;
			state.ftpCreateData = null;
			state.stoneSearchFtpUpdateData = [];
			state.stoneSearchFtpCreateDataSuccess = [];
			state.FtpCreateError = [];
		},
		ftpFeedResestData: (state) => {
			state.isLoading = false;
			state.error = null;
			state.ftpfeedTotalCountData = [];
		},
	},
});

export const {
	stoneSearchFtpGetData,
	stoneSearchFtpFeedDataList,
	stoneSearchFtpfeedTotalCountDataList,
	stoneSearchFtpViewData,
	stoneSearchFtpCreateData,
	stoneSearchFtpUpdateData,
	setLoading,
	stoneSearchFtpgetAllDatalist,
	stoneSearchFtpErrorList,
	stoneSearchstoneSearchFtpDeleteData,
	createSuccess,
	stoneSearchFtpResetAuth,
	FtpCreateError,
	ftpFeedResestData
} = stoneSearchFtpsSlice.actions;

export default stoneSearchFtpsSlice.reducer;

export const stoneSearchFtpClientList = (queryString) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpListApi(queryString, config);

		dispatch(stoneSearchFtpGetData(data));
	} catch (err) {
		dispatch(stoneSearchFtpErrorList(err));
	}
};

export const stoneSearchFtpFeedRecordList = (queryString) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpFeedApi(queryString, config);
		dispatch(stoneSearchFtpFeedDataList(data));
	} catch (err) {
		dispatch(stoneSearchFtpErrorList(err));
	}
};

export const stoneSearchFtpfeedTotalCountRecordList = (queryString) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpFeedApi(queryString, config);
		dispatch(stoneSearchFtpfeedTotalCountDataList(data));
	} catch (err) {
		dispatch(stoneSearchFtpErrorList(err));
	}
};

export const stoneSearchFtpGetDataDrowpDown = () => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpGetAllApi(config);
		dispatch(stoneSearchFtpgetAllDatalist(data));
	} catch (err) {
		dispatch(stoneSearchFtpErrorList(err));
	}
};

export const stoneSearchFtpViewList = (id) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpViewApi(id, config);

		dispatch(stoneSearchFtpViewData(data));
	} catch (err) {
		dispatch(stoneSearchFtpErrorList(err));
	}
};

export const stoneSearchFtpUpdateList = (id, updatedata) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpUpdateApi(id, updatedata, config);

		const { statusCode, message } = data;
		if (statusCode === 200) {
			toast.success(message);
			dispatch(stoneSearchFtpUpdateData(data));
		}
	} catch (error) {
		if (error.response && error.response.data.errors) {
			return dispatch(stoneSearchFtpErrorList(error.response.data.errors));
		} else {
			return dispatch(stoneSearchFtpErrorList(error.message));
		}
	}
};

export const stoneSearchFtpCreateRequest = (ftpData) => async (dispatch, getState) => {
	dispatch(setLoading());
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};

		const { data } = await stoneSearchFtpCreateApi(ftpData, config);

		const { statusCode, message } = data;

		if (statusCode === 201) {
			toast.success(message);
			dispatch(stoneSearchFtpCreateData(data));
		}
	} catch (error) {
		if (error.response && error.response.data.errors) {
			return dispatch(stoneSearchFtpErrorList(error.response.data.errors));
		} else {
			return dispatch(stoneSearchFtpErrorList(error.message));
		}
	}
};

export const stoneSearchFtpDeleteRequest = (delete_id) => async (dispatch, getState) => {
	dispatch(setLoading());
	const toastId = toast.loading('Please wait your data is deleteing...');
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: getState()?.auth?.Token,
			},
		};
		const { data } = await stoneSearchFtpDeleteApi(delete_id, config);

		const { statusCode, error, errors, message } = data;
		if (error) {
			dispatch(stoneSearchFtpErrorList(errors));
		}
		if (statusCode === 200) {
			toast.success(message, {
				id: toastId,
			});
			dispatch(stoneSearchstoneSearchFtpDeleteData(message));
			dispatch(stoneSearchFtpClientList(data));
		}
	} catch (error) {
		const { statusCode, message } = error.response.data;
		if (statusCode === 422) {
			toast.error(message, {
				id: toastId,
			});
		}
	}
};
