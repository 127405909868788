import { createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
import {
    ExcelTypetwo,
    FtpGetAllApi,
    ImagePositionApi,
    ImageUploadApi,
    ImageUploadDeleteApi,
    ProductApi,
    ProductCsvApi,
    ProductExcelUploadTypeOne,
    ProductsDetailApi,
    ProductsMultiDeleteApi,
    SendFeedAPI,
    SingleImageUploadApi,
    VideoSirvUploadApi,
    lithosDownloadProductsApi,
    lithosExcelTypetwo,
    lithosImagePositionApi,
    lithosImageUploadApi,
    lithosImageUploadDeleteApi,
    lithosProductApi,
    lithosProductCsvApi,
    lithosProductExcelUploadTypeOne,
    lithosProductUnavailableApi,
    lithosProductsDetailApi,
    lithosProductsMultiDeleteApi,
    lithosSendFeedAPI,
    lithosSingleImageUploadApi,
    productUnavailableApi,
    stoneSearchSendFeedAPI,
} from '../services/api';
import toast from 'react-hot-toast';

const accessToken = JSON.parse(localStorage.getItem('accessToken'));
let headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
    Authorization: accessToken,
};

export const lithosProductsSlice = createSlice({
    name: 'lithosProducts',
    initialState: {
        isLoading: false,
        productData: [],
        productCsvData: [],
        productViewData: [],
        singleUploadImg: [],
        ImageUploaFileData: [],
        ImagePositionData: [],
        VideoUploaFileData: [],
        imageuploadDeleteData: [],
        MultiDeleteData: [],
        excelTypeOne: [],
        excelTypeTwo: [],
        ftpGetAllData: [],
        FeedData: [],
        productUnAvailableExcelMessage: null,
        isExcelUploadSuccessmessage: false,
        error: null,
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        productGetData: (state, action) => {
            state.isLoading = false;
            state.productData = action.payload;
        },

        productCsvList: (state, action) => {
            state.isLoading = false;
            state.productCsvData = action.payload;
        },

        productViewData: (state, action) => {
            state.isLoading = false;
            state.productViewData = action.payload;
        },

        singleUploadImgData: (state, action) => {
            state.isLoading = false;
            state.singleUploadImg = action.payload;
        },

        excelTypeOne: (state, action) => {
            state.isLoading = false;
            state.excelTypeOne = action.payload;
        },
        excelTypeTwo: (state, action) => {
            state.isLoading = false;
            state.excelTypeTwo = action.payload;
        },
        ImageUploaFileData: (state, action) => {
            state.isLoading = false;
            state.ImageUploaFileData = action.payload;
        },
        ImagePositionDataList: (state, action) => {
            state.isLoading = false;
            state.ImagePositionData = action.payload;
        },

        VideoUploaFile: (state, action) => {
            state.isLoading = false;
            state.VideoUploaFileData = action.payload;
        },

        ImageUploadDataDeleteList: (state, action) => {
            state.isLoading = false;
            state.imageuploadDeleteData = action.payload;
        },

        MultiDataDeleteList: (state, action) => {
            state.isLoading = false;
            state.MultiDeleteData = action.payload;
        },

        handleErrorList: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        handleErrorExcel: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        FeedData: (state, action) => {
            state.isLoading = false;
            state.FeedData = action.payload;
        },

        productUnAvailableExcelData: (state, action) => {
            state.isLoading = false;
            state.productUnAvailableExcelMessage = action.payload;
        },
        FeedDataError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        handleError: (state, action) => {
            state.error = action.payload;
            state.isLoading = true;
        },
        handleSuccessProductPull: (state) => {
            state.isLoading = false;
        },
        lithosIsExcelUploadSuccess: (state, action) => {
            state.isExcelUploadSuccessmessage = action.payload;
        },
        ProductResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.ImageUploaFileData = [];
            state.excelTypeOne = [];
            state.excelTypeTwo = [];
            state.productViewData = [];
            state.singleUploadImg = [];
        },
        lithosProductUnavailableResetMessage: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productUnAvailableExcelMessage = null;
        },
    },
});

export const {
    productGetData,
    productCsvList,
    productViewData,
    singleUploadImgData,
    ImageUploaFileData,
    VideoUploaFile,
    ImageUploadDataDeleteList,
    ImagePositionDataList,
    MultiDataDeleteList,
    handleErrorList,
    excelTypeOne,
    excelTypeOneReset,
    setLoading,
    FeedData,
    productUnAvailableExcelData,
    handleErrorExcel,
    FeedDataError,
    ProductResetData,
    lithosProductUnavailableResetMessage,
    excelTypeTwo,
    lithosIsExcelUploadSuccess,
    handleSuccessProductPull,
    handleError
} = lithosProductsSlice.actions;

export default lithosProductsSlice.reducer;

export const lithosProductList = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosProductApi(queryString, config);
        dispatch(productGetData(data));
    } catch (err) {
        dispatch(handleErrorList(err));
    }
};

export const lithosProductCsvListData = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosProductCsvApi(queryString, config);
        dispatch(productCsvList(data));
    } catch (err) {
        dispatch(handleErrorList(err));
    }
};

export const lithosImagePositionRequest = (img_position) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosImagePositionApi(img_position, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(ImagePositionDataList(data));
            dispatch(lithosProductList());
            dispatch(lithosProductCsvListData());
        }
    } catch (error) {
        const { statusCode, errors } = error.response.data;
        if (statusCode === 422) {
            dispatch(handleErrorList(errors));
            toast.error(errors?.message, {
                id: toastId,
            });
        }
    }
};

export const lithosSingleUploadImgRequest = (img_file) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosSingleImageUploadApi(img_file, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(singleUploadImgData(data));
            dispatch(lithosProductList());
            dispatch(lithosProductCsvListData());
        }
    } catch (error) {
        const { statusCode } = error.response.data;
        if (statusCode === 422) {
            dispatch(handleErrorList(error.response.data.message));
            toast.error(error.response.data?.message, {
                id: toastId,
            });
        }
    }
};

export const lithosImagesUploadRequest = (img_upload) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your folder is Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosImageUploadApi(img_upload, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(ImageUploaFileData(data));
            dispatch(lithosProductList());
        }
    } catch (error) {
        const { statusCode, errors } = error.response.data;
        if (statusCode === 422) {
            dispatch(handleErrorList(errors));
            toast.error(errors.message, {
                id: toastId,
            });
        }
        // if (error.response && error.response.data.errors) {
        // 	return dispatch(handleErrorList(error.response.data.errors));
        // } else {
        // 	return dispatch(handleErrorList(error.message));
        // }
    }
};

export const lithosVideoSirvUploadRequest = (video_upload) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your folder is Uploading...');

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await VideoSirvUploadApi(video_upload, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(VideoUploaFile(data));
        }
    } catch (error) {
        const { statusCode, errors } = error.response.data;
        if (statusCode === 422) {
            dispatch(handleErrorList(errors));
            toast.error(errors.message, {
                id: toastId,
            });
        }
    }
};

export const lithosImageUploadDeleteRequest = (deleteRequest) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };

        const { data } = await lithosImageUploadDeleteApi(deleteRequest, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(ImageUploadDataDeleteList(data));
            dispatch(lithosProductList());
            dispatch(lithosProductCsvListData());
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId,
            });
            dispatch(handleErrorList(message));
        }
    }
};

export const lithosProductsDetialRequest = (details_id) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosProductsDetailApi(details_id, config);

        const { statusCode } = data;
        if (statusCode === 200) {
            dispatch(productViewData(data));
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            toast.error(message);
        }
    }
};

export const lithosProductsMultiDeleteRequest = (DeleteIds) => async (dispatch, getState) => {
    const toastId = toast.loading('Please wait,Processing...');
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosProductsMultiDeleteApi(DeleteIds, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(MultiDataDeleteList(data));
            dispatch(lithosProductList());
        }
    } catch (error) {
        console.log(error, "error")
        if (error.response && error.response.data)
            dispatch(handleErrorList(error.response.data.message));
        toast.error(error.response?.data?.message, {
            id: toastId,
        });
    }
};

export const lithosProductExcelUpload = (uploadfile) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your excel is Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosProductExcelUploadTypeOne(uploadfile, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(lithosIsExcelUploadSuccess(true));

            dispatch(excelTypeOne(data));
            dispatch(lithosProductList());
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            dispatch(handleErrorExcel(message));
            toast.error(message, {
                id: toastId,
            });
        }
    }
};

export const lithosExcelUploadTypeTwo = (exceluploadtwo) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your excel type two is Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosExcelTypetwo(exceluploadtwo, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(excelTypeTwo(data));
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            dispatch(handleErrorExcel(message));
            toast.error(message, {
                id: toastId,
            });
        }
    }
};

export const lithosSendFeed = (sendFeedData) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait FTP connection establish...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosSendFeedAPI(sendFeedData, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            dispatch(FeedData(data));
            toast.success(message, {
                id: toastId,
            });
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            dispatch(FeedDataError(message));
            toast.error(message, {
                id: toastId,
            });
        }
    }
};
export const lithosStoneSearchSendFeed = (sendFeedData, queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait FTP connection establish...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await stoneSearchSendFeedAPI(sendFeedData, queryString, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            dispatch(FeedData(data));
            toast.success(message, {
                id: toastId,
            });
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            dispatch(FeedDataError(message));
            toast.error(message, {
                id: toastId,
            });
        }
    }
};

export const lithosProductUnavailableExcelRequest = (unavailableExcel) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your excel Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const { data } = await lithosProductUnavailableApi(unavailableExcel, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(productUnAvailableExcelData(message));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            dispatch(handleErrorExcel(message));
            toast.error(message, {
                id: toastId,
            });
        }
    }
};
export const lithosDownloadProductRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your products are pulling...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token,
            },
        };
        const {data}  = await lithosDownloadProductsApi(config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
            });
            dispatch(handleSuccessProductPull(message));
            dispatch(lithosProductList(queryString));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            dispatch(handleError(message));
            toast.error(message, {
                id: toastId,
            });
        }
    }
};
