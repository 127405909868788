// ** Reducers Imports
import layout from './layoutSlice';
import navbar from './navbarSlice';
import auth from './authSlice';
import products from './productsSlice';
import Ftps from './FtpsSlice';
import FtpLog from './FtpLogSlice';
import LithosFtpLog from './LithosFtpLogSlice';
import ExcelLog from './ExcelLogSlice';
import lithosExcelLog from './LithosExcelLogSlice';
import forgotPassword from './ForgotPasswordSlice';
import user from './userSlice';
import changePassword from './ChagePasswordSlice';
import ResetPassword from './ResetPasswordSlice';
import ShopifySync from './ShopifySyncSlice';
import Dashboard from './DashboardSlice';
import ShopifySyncLog from './ShopifySyncLogSlice';
import lithosShopifySyncLog from "./LithosShopifySyncLogSlice"
import mediaLog from './mediaLogSlice';
import lithosMediaLog from './lithosMediaLogSlice';
import stoneSearchFtps from './stoneSearchFtpsSlice';
import stoneSearchFtpLog from './StoneSearchFtpLogSlice';
import lithosProducts from "./lithosProductsSlice";
import lithosFtps from "./lithosFtpsSlice";
import lithosShopifySync from "./lithosShopifySyncSlice";
const rootReducer = {
	navbar,
	layout,
	auth,
	forgotPassword,
	products,
	Ftps,
	FtpLog,
	LithosFtpLog,
	ExcelLog,
	lithosExcelLog,
	user,
	ShopifySyncLog,
	lithosShopifySyncLog,
	ResetPassword,
	changePassword,
	ShopifySync,
	mediaLog,
	lithosMediaLog,
	Dashboard,
	stoneSearchFtps,
	stoneSearchFtpLog,
	lithosProducts,
	lithosFtps,
	lithosShopifySync

};

export default rootReducer;
